import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { BreakoutGroup } from "../../api-models/BreakoutGroup";
import { BreakoutGroupRegistration } from "src/app/api-models/BreakoutGroupRegistration";

export interface IBreakoutGroupConfigService {
    breakoutGroupsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const BreakoutGroupConfigService = new InjectionToken<IBreakoutGroupConfigService>("BreakoutGroupConfig");

@Injectable()
export class BreakoutGroupsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(BreakoutGroupConfigService) private breakoutGroupConfig: IBreakoutGroupConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.breakoutGroupConfig.utcDateFormat;
        this.datatableStateSaveMode = this.breakoutGroupConfig.datatableStateSaveMode;
    }

    public breakoutGroups(params: IApiListQueryParameter): Observable<{ list: BreakoutGroup[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getBreakoutGroupsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(BreakoutGroup, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public breakoutGroupDetail(id: string): Observable<BreakoutGroup> {
        return this.http
            .get<Object>(this.getBreakoutGroupsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroup, result)));
    }

    public addBreakoutGroup(breakoutGroup: BreakoutGroup): Observable<BreakoutGroup> {
        return this.http
            .post<Object>(this.getBreakoutGroupsServiceUrl(""), classToPlain(breakoutGroup), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroup, result)));
    }

    public updateBreakoutGroup(breakoutGroup: BreakoutGroup): Observable<BreakoutGroup> {
        return this.http
            .patch<Object>(this.getBreakoutGroupsServiceUrl(breakoutGroup._id), classToPlain(breakoutGroup), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroup, result)));
    }

    public deleteBreakoutGroup(id: string): Observable<BreakoutGroup> {
        return this.http
            .delete<Object>(this.getBreakoutGroupsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroup, result)));
    }

    public breakoutGroupRegistrations(id: string): Observable<BreakoutGroupRegistration[]> {
        return this.http
            .get<Object[]>(this.getBreakoutGroupsServiceUrl(`${id}/registrations/`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroupRegistration, result)));
    }

    public deleteBreakoutGroupRegistration(id: string, regId: string): Observable<BreakoutGroupRegistration[]> {
        return this.http
            .delete<Object[]>(this.getBreakoutGroupsServiceUrl(`${id}/registrations/${regId}`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(BreakoutGroupRegistration, result)));
    }

    private getBreakoutGroupsServiceUrl(postfix: string): string {
        return this.breakoutGroupConfig.breakoutGroupsAPI + postfix;
    }
}
