<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'breakoutgroups.list.title' | translate }}</h1>
        <small>{{ 'breakoutgroups.list.subtitle' | translate }}</small>


        <div class="actions"> 
            <button *appHasPermission="'BREAKOUTGROUPS.ADD'" (click)="addBreakoutGroup()" class="btn btn-success btn--icon-text" style="margin-left:25px;"><i class="zmdi zmdi-collection-plus"></i> {{ 'breakoutgroups.list.add' | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'BREAKOUTGROUPS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ 'breakoutgroups.list.card.title' | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="key">{{ 'breakoutgroups.props.key' | translate }}</th>
                        <th id="name">{{ 'breakoutgroups.props.name' | translate }}</th>
                        <th id="link">{{ 'breakoutgroups.props.link' | translate }}</th>
                        <th id="memberLimit">{{ 'breakoutgroups.props.memberLimit' | translate }}</th>
                        <th id="created">{{ 'breakoutgroups.props.created' | translate }}</th>
                        <th id="updated">{{ 'breakoutgroups.props.updated' | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.key }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.link }}</td>
                        <td>{{ row.memberLimit }}</td>
                        <td>{{ row.created | date:UTCDATEFORMAT:'+0' }}</td>
                        <td>{{ row.updated | date:UTCDATEFORMAT:'+0' }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editBreakoutGroup(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'BREAKOUTGROUPS.EDIT'"></i>
                            <i (click)="deleteBreakoutGroup(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'BREAKOUTGROUPS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ 'global.datatable.empty' | translate }}</td>
                    </tr>
                <tbody>
            </table>
        </div>
    </div>
</div>