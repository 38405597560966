<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "breakoutgroups.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "breakoutgroups.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="breakoutGroup" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12" *ngIf="MODE === 'ADD'">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.key" | translate }}</label>
                        <input name="key" type="text" class="form-control" [(ngModel)]="breakoutGroup.key" #key="ngModel" [ngClass]="{ 'is-invalid': f.submitted && key.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.key" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.name" | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="breakoutGroup.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.name" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.link" | translate }}</label>
                        <input name="link" type="text" class="form-control" [(ngModel)]="breakoutGroup.link" #link="ngModel" [ngClass]="{ 'is-invalid': f.submitted && link.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.link" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.memberLimit" | translate }}</label>
                        <input name="memberLimit" type="number" class="form-control" [(ngModel)]="breakoutGroup.memberLimit" #memberLimit="ngModel" [ngClass]="{ 'is-invalid': f.submitted && memberLimit.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.memberLimit" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="MODE === 'EDIT'">
        <div class="card-body">
            <h4 class="card-title">{{ "breakoutgroups.detail.registrations.title" | translate }}</h4>

            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>{{ "breakoutgroups.detail.registrations.props.firstname" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.registrations.props.lastname" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.registrations.props.email" | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let registration of breakoutGroupRegistrations">
                        <td>{{ registration?.user?.firstname }}</td>
                        <td>{{ registration?.user?.lastname }}</td>
                        <td>{{ registration?.user?.email }}</td>
                        <td class="text-right nowrap">
                            <i (click)="deleteBreakoutGroupRegistration(registration._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
