import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class BreakoutGroupRegistration {
    _id?: string;

    user: {
        _id: string;
        email: string;
        firstname: string;
        lastname: string;
    };

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
