import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { BreakoutGroupsService } from "./breakoutgroups.service";
import { TranslateService } from "@ngx-translate/core";
import { BreakoutGroup } from "../../api-models/BreakoutGroup";
import { BsModalService } from "ngx-bootstrap/modal";
var BreakoutGroupDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BreakoutGroupDetailComponent, _super);
    function BreakoutGroupDetailComponent(route, breakoutGroupsService, router, alertService, translateService, _location, modalService) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.breakoutGroupsService = breakoutGroupsService;
        _this.router = router;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this._location = _location;
        _this.modalService = modalService;
        _this.breakoutGroupId = null;
        _this.breakoutGroup = null;
        _this.breakoutGroupRegistrations = [];
        _this.subscriptions.push(_this.route.params.subscribe(function (params) {
            _this.breakoutGroupId = params.id;
            if (_this.breakoutGroupId) {
                _this.initEditMode();
            }
            else {
                _this.initAddMode();
            }
        }));
        return _this;
    }
    BreakoutGroupDetailComponent.prototype.ngOnInit = function () { };
    BreakoutGroupDetailComponent.prototype.initAddMode = function () {
        this.MODE = BaseComponentMode.ADD;
        this.breakoutGroup = new BreakoutGroup();
    };
    BreakoutGroupDetailComponent.prototype.initEditMode = function () {
        var _this = this;
        this.MODE = BaseComponentMode.EDIT;
        this.subscriptions.push(this.breakoutGroupsService.breakoutGroupDetail(this.breakoutGroupId).subscribe(function (result) {
            _this.breakoutGroup = result;
        }, function (err) {
            _this.router.navigate(["../"], { relativeTo: _this.route });
        }));
        this.subscriptions.push(this.breakoutGroupsService.breakoutGroupRegistrations(this.breakoutGroupId).subscribe(function (result) {
            _this.breakoutGroupRegistrations = result;
        }));
    };
    BreakoutGroupDetailComponent.prototype.save = function () {
        var _this = this;
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(this.breakoutGroupsService.addBreakoutGroup(this.breakoutGroup).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("breakoutgroups.detail.add.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(this.breakoutGroupsService.updateBreakoutGroup(this.breakoutGroup).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("breakoutgroups.detail.edit.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
    };
    BreakoutGroupDetailComponent.prototype.deleteBreakoutGroupRegistration = function (regId) {
        var _this = this;
        var initialState = {
            title: this.translateService.instant("breakoutgroups.detail.registrations.delete.title"),
            message: this.translateService.instant("breakoutgroups.detail.registrations.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        var bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState: initialState });
        bsModalRef.content.confirmCallback = function () {
            _this.subscriptions.push(_this.breakoutGroupsService.deleteBreakoutGroupRegistration(_this.breakoutGroup._id, regId).subscribe(function (result) {
                _this.breakoutGroupRegistrations = result;
                _this.alertService.addSuccess(_this.translateService.instant("breakoutgroups.list.delete.success"));
            }));
        };
    };
    BreakoutGroupDetailComponent.prototype.back = function () {
        this._location.back();
    };
    return BreakoutGroupDetailComponent;
}(BaseComponent));
export { BreakoutGroupDetailComponent };
