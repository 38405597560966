import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { BreakoutGroupsService } from "./breakoutgroups.service";
import { TranslateService } from "@ngx-translate/core";
import { BreakoutGroup } from "../../api-models/BreakoutGroup";
import { BreakoutGroupRegistration } from "../../api-models/BreakoutGroupRegistration";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-breakoutgroupdetail",
    templateUrl: "./breakoutgroupdetail.component.html",
    styleUrls: ["breakoutgroupdetail.component.scss"]
})
export class BreakoutGroupDetailComponent extends BaseComponent implements OnInit {
    private breakoutGroupId: string = null;
    public breakoutGroup: BreakoutGroup = null;

    public breakoutGroupRegistrations: BreakoutGroupRegistration[] = [];

    constructor(
        private route: ActivatedRoute,
        private breakoutGroupsService: BreakoutGroupsService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.breakoutGroupId = params.id;
                if (this.breakoutGroupId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.breakoutGroup = new BreakoutGroup();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.breakoutGroupsService.breakoutGroupDetail(this.breakoutGroupId).subscribe(
                (result) => {
                    this.breakoutGroup = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );

        this.subscriptions.push(
            this.breakoutGroupsService.breakoutGroupRegistrations(this.breakoutGroupId).subscribe((result) => {
                this.breakoutGroupRegistrations = result;
            })
        );
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.breakoutGroupsService.addBreakoutGroup(this.breakoutGroup).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.breakoutGroupsService.updateBreakoutGroup(this.breakoutGroup).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    deleteBreakoutGroupRegistration(regId) {
        const initialState = {
            title: this.translateService.instant("breakoutgroups.detail.registrations.delete.title"),
            message: this.translateService.instant("breakoutgroups.detail.registrations.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.breakoutGroupsService.deleteBreakoutGroupRegistration(this.breakoutGroup._id, regId).subscribe((result) => {
                    this.breakoutGroupRegistrations = result;
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.list.delete.success"));
                })
            );
        };
    }

    back() {
        this._location.back();
    }
}
