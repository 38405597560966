import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BreakoutGroupsService } from "./breakoutgroups.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { BreakoutGroup } from "../../api-models/BreakoutGroup";
import { BreakoutGroupsMainComponent } from "./breakoutgroups.main.component";

@Component({
    selector: "app-breakoutgroups",
    templateUrl: "./breakoutgroups.component.html",
    styleUrls: ["breakoutgroups.component.scss"]
})
export class BreakoutGroupsComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: BreakoutGroup[] = [];

    constructor(
        private breakoutGroupsService: BreakoutGroupsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        @Host() parent: BreakoutGroupsMainComponent
    ) {
        super(translateService, {
            stateSaveMode: breakoutGroupsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_breakoutgroups_list"
        });

        this.dtOptions.order = [[0, "asc"]];
        this.UTCDATEFORMAT = this.breakoutGroupsService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.breakoutGroupsService.breakoutGroups(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "key", orderable: true },
            { data: "name", orderable: true },
            { data: "link", orderable: true },
            { data: "memberLimit", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addBreakoutGroup() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editBreakoutGroup(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteBreakoutGroup(id: string) {
        const initialState = {
            title: this.translateService.instant("breakoutgroups.list.delete.title"),
            message: this.translateService.instant("breakoutgroups.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.breakoutGroupsService.deleteBreakoutGroup(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}
