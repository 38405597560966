import { Component, OnInit, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { PermissionService, OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { Router } from "@angular/router";

@Component({
    selector: "app-sidebar",
    templateUrl: "sidebar.component.html",
    styleUrls: ["sidebar.component.scss"],
    host: {
        class: "sidebar"
    },
    animations: [
        trigger("toggleSubMenu", [
            state(
                "inactive",
                style({
                    height: "0",
                    opacity: "0"
                })
            ),
            state(
                "active",
                style({
                    height: "*",
                    opacity: "1"
                })
            ),
            transition("inactive => active", animate("200ms ease-in")),
            transition("active => inactive", animate("200ms ease-out"))
        ])
    ]
})
export class SidebarComponent implements OnInit, AfterViewInit {
    /* Main Menu

     * title: Main menu title
     * icon: Menu icon from material-design-iconic-fonts. Please refer "Icons" page for more details
     * route: Router link for page
     * sub: Sub menus
     * visibility: Property for animation. "inactive" means the sub menu is hidden by default.

     */
    mainMenu: any[] = [];

    tokenExpires: Date = new Date();

    // Toggle sub menu
    toggleSubMenu(i) {
        this.mainMenu[i].visibility = this.mainMenu[i].visibility === "inactive" ? "active" : "inactive";
    }

    constructor(private readonly translate: TranslateService, private readonly permissionService: PermissionService, private readonly router: Router, private readonly oauthStorageAdapter: OAuthStorageAdapter) {
        this.mainMenu = [
            {
                title: this.translate.instant("menu.home"),
                icon: "home",
                route: "/home",
                permission: "AUTHENTICATED"
            },
            {
                title: this.translate.instant("menu.user.title"),
                icon: "accounts-alt",
                permission: "MENU.USERMANAGEMENT",
                sub: [
                    {
                        title: this.translate.instant("menu.user.users"),
                        route: "/usermanagement/users",
                        permission: "MENU.USERMANAGEMENT.USERS"
                    },
                    {
                        title: this.translate.instant("menu.user.roles"),
                        route: "/usermanagement/roles",
                        permission: "MENU.USERMANAGEMENT.ROLES"
                    },
                    {
                        title: this.translate.instant("menu.user.organizations"),
                        route: "/usermanagement/organizations",
                        permission: "MENU.USERMANAGEMENT.ORGANIZATIONS"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.breakoutgroups"),
                icon: "local-offer",
                route: "/breakoutgroups",
                permission: "MENU.BREAKOUTGROUPS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.languages"),
                icon: "flag",
                route: "/languages",
                permission: "MENU.LANGUAGES",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.mail.title"),
                icon: "email",
                permission: "MENU.MAIL",
                sub: [
                    {
                        title: this.translate.instant("menu.mail.mailaccounts"),
                        route: "/mail/mailaccounts",
                        permission: "MENU.MAIL.MAILACCOUNTS"
                    },
                    {
                        title: this.translate.instant("menu.mail.mailtemplates"),
                        route: "/mail/mailtemplates",
                        permission: "MENU.MAIL.MAILTEMPLATES"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.registration"),
                icon: "settings",
                route: "/registrationconfig",
                permission: "MENU.REGISTRATION",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.systemhealth.title"),
                icon: "dns",
                permission: "MENU.SYSTEMHEALTH",
                sub: [
                    {
                        title: this.translate.instant("menu.systemhealth.systemerrors"),
                        route: "/systemhealth/errors",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMERRORS"
                    },
                    {
                        title: this.translate.instant("menu.systemhealth.systemlogmessages"),
                        route: "/systemhealth/logs",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMLOGMESSAGES"
                    },
                    {
                        title: this.translate.instant("menu.systemhealth.systemjobperformances"),
                        route: "/systemhealth/jobs",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMJOBPERFORMANCES"
                    },
                    {
                        title: this.translate.instant("menu.systemhealth.systemresourcesnapshots"),
                        route: "/systemhealth/resources",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.crashlogs.title"),
                icon: "alert-octagon",
                permission: "MENU.CRASHLOGS",
                sub: [
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogapplications"),
                        route: "/crashlogs/crashlogapplications",
                        permission: "MENU.CRASHLOGS.CRASHLOGAPPLICATIONS"
                    },
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogerrors"),
                        route: "/crashlogs/crashlogerrors",
                        permission: "MENU.CRASHLOGS.CRASHLOGERRORS"
                    },
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogmessages"),
                        route: "/crashlogs/crashlogmessages",
                        permission: "MENU.CRASHLOGS.CRASHLOGMESSAGES"
                    }
                ],
                visibility: "inactive"
            }
        ];
        this.filterByPermissions();
        this.tokenExpires = new Date(this.oauthStorageAdapter.getOAuthLoginResult().accessTokenExpiresAt);
    }

    ngOnInit() {}

    private filterByPermissions(): void {
        const filteredMenu = this.mainMenu.filter((menu) => {
            if (menu.sub && menu.sub.length > 0) {
                const filteredSubmenu = menu.sub.filter((sub) => this.permissionService.hasPermission(sub.permission));
                menu.sub = filteredSubmenu;
            }
            return this.permissionService.hasPermission(menu.permission);
        });
        this.mainMenu = filteredMenu;
    }

    ngAfterViewInit() {
        this.mainMenu.forEach((mainMenuNode) => {
            if (mainMenuNode.sub) {
                mainMenuNode.sub.forEach((subMenuNode) => {
                    if (this.router.isActive(subMenuNode.route, false)) {
                        setTimeout(() => {
                            mainMenuNode.visibility = "active";
                        }, 0);
                    }
                });
            }
        });
    }
}
