import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class BreakoutGroup {
    _id?: string;

    key: string;
    name: string;
    memberLimit: number;

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
